import React from "react";
import { useContext } from "react";

import clsx from "clsx";

import { ThemeContext } from "../context/ThemeContext";

type Props = {
  title: string;
  level: 1 | 2 | 3 | 4;
  className?: string;
  bgClassName?: string;
  textSizeClassName?: string;
  paddingClassName?: string;
};

const Headline = ({
  title,
  level = 4,
  className = "",
  bgClassName,
  textSizeClassName,
  paddingClassName,
}: Props) => {
  const { isHeightContrastEnable } = useContext(ThemeContext);
  return React.createElement(
    `h${level}`,
    {
      className: clsx(
        isHeightContrastEnable && "text-white",
        bgClassName ?? "bg-gray-50",
        className,
        textSizeClassName ?? {
          "text-3xl md:text-4xl": level === 1,
          "text-2xl md:text-3xl": level === 2,
          "text-xl md:text-2xl": level === 3,
          "text-lg md:text-xl": level === 4,
        },
        paddingClassName ?? {
          "px-6 py-4 md:px-8 md:py-6": level === 1 || level === 2,
          "px-3 py-2 md:p-4": level === 3 || level === 4,
        },
        {
          "rounded-b-md": level === 1 || level === 2,
          "rounded-b-sm": level === 3 || level === 4,
        }
      ),
    },
    title
  );
};

export default Headline;
