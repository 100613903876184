import React, { useContext, useState } from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import {
  faBone,
  faCheck,
  faMars,
  faPaw,
  faShieldCat,
  faShieldDog,
  faTimes,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import FeaturesCard from "../components/FeaturesCard";
import Headline from "../components/Headline";
import Layout from "../components/Layout";
import PhotoPopup from "../components/PhotoPopup";
import { SEO } from "../components/SEO";
import { ThemeContext } from "../context/ThemeContext";
import { createStringAge } from "../shared/utils/AgeCreator";
import { dateDisplay } from "../shared/utils/dateDisplay";
import { createPageTitle } from "../shared/utils/seo";
import { createAnimalDetailsUrl } from "../shared/utils/urls";

const AdoptionDetails = ({ pageContext }) => {
  const { themesLayout, themesLayoutContrast, isHeightContrastEnable } =
    useContext(ThemeContext);

  const theme = isHeightContrastEnable ? themesLayoutContrast : themesLayout;

  const [showPopup, setShowPopup] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const animalData = pageContext?.data;
  const animalFeatures = animalData.features;
  const animalKind = animalData.kind;
  const isDifficultToAdopt = animalData.isDifficult;

  const animalPhotos = animalData.photos;
  const firstPhoto = animalData.photos?.[0]?.directus_files_id ?? {};
  const imageData = getImage(firstPhoto.imageFile);

  const creationTimestamp = new Date(animalData.date_created).valueOf() / 1000;
  const todaysDate = new Date().getDate();

  const gradientIndex = (creationTimestamp + todaysDate) % theme.length;
  const gradient = theme[gradientIndex];

  const getFeatureNames = (type) =>
    animalFeatures
      .filter((item) => item.animal_feature_id?.group === `${type}`)
      .map((feature) => feature?.animal_feature_id?.feature_name);

  const displayPhotoDescription = (index) => {
    return animalPhotos[index]?.directus_files_id.description;
  };

  const pageTitle = `${animalData.name} – ${
    animalKind === "dog" ? "pies" : "kot"
  } do adopcji`;

  return (
    <>
      {showPopup ? (
        <PhotoPopup
          onClose={() => {
            setShowPopup(false);
            setCurrentPhotoIndex(0);
          }}
          photo={getImage(
            animalPhotos[currentPhotoIndex]?.directus_files_id?.imageFile
          )}
          photoAlt={displayPhotoDescription(currentPhotoIndex) ?? ""}
          photos={animalPhotos}
          arrowEnabled={true}
          theme={gradient}
          currentPhotoIndex={currentPhotoIndex}
          onPhotoChange={setCurrentPhotoIndex}
        />
      ) : null}
      <Layout theme={gradient}>
        <SEO
          title={createPageTitle(pageTitle)}
          description={animalData.descriptionText}
          urlPath={createAnimalDetailsUrl(animalData.shelter_id)}
          imagePath={firstPhoto?.imageFile?.previewImage?.resize?.src}
        />
        <div className="container mx-auto animate-fade-in-down px-2 pt-8 md:px-8 xl:px-16">
          <div
            className={clsx(
              gradient.bgStyle,
              "grid grid-cols-12 gap-2 rounded-t-md px-3 py-5"
            )}
          >
            <Headline
              level={1}
              title={animalData.name}
              className="col-span-12 mb-2 mt-4 text-center font-bold lg:w-full"
            />
            {/* PHOTO */}
            {imageData && (
              <button
                className={clsx(
                  "group relative col-span-12 flex h-full w-full cursor-pointer justify-center overflow-hidden",
                  "rounded-md shadow-sm transition duration-300 ease-in-out",
                  "hover:scale-95 focus:scale-95 md:col-span-6 lg:rounded-l-md lg:rounded-r-none xl:col-span-7"
                )}
                onClick={() => setShowPopup(true)}
              >
                <span
                  className={clsx(
                    "absolute z-10 flex h-full w-full items-center justify-center",
                    "bg-black text-white opacity-0 duration-300 ease-in-out",
                    "hover:scale-150 focus:opacity-70 group-hover:text-2xl group-hover:opacity-70",
                    "group-focus:scale-150 group-focus:text-2xl group-focus:opacity-70"
                  )}
                >
                  🔍Powiększ
                </span>
                <GatsbyImage
                  className="my-auto h-full max-h-50-screen w-full flex-1"
                  image={imageData}
                  alt={displayPhotoDescription(0) ?? ""}
                />
              </button>
            )}

            {/* CMS DATA */}
            <div className="order-1 col-span-12 justify-center bg-white text-center md:col-span-6 lg:rounded-l-none lg:rounded-r-md xl:order-none xl:col-span-5">
              <Headline
                title="Dane"
                level={3}
                className="mb-3 bg-white text-left font-semibold text-gray-500"
              />
              <div className="flex w-full flex-wrap pb-6">
                <h2 className="w-full text-lg font-bold">Data przyjęcia</h2>
                <h3 className="w-full py-2">
                  {dateDisplay(animalData.date_admission)}
                </h3>
              </div>
              <ul className="grid gap-4 sm:grid-cols-2 lg:gap-8">
                <li className="flex flex-wrap">
                  <h2 className="w-full text-sm font-bold">
                    Numer ewidencyjny
                  </h2>
                  <h3 className="w-full py-2">{animalData.shelter_id}</h3>
                </li>
                <li className="flex flex-wrap">
                  <h2 className="w-full text-sm font-bold">Płeć</h2>
                  <h3
                    className={clsx(
                      "flex w-full items-center justify-center space-x-2 py-2",
                      animalData.sex === "male"
                        ? "text-blue-500"
                        : "text-pink-500"
                    )}
                  >
                    <span>
                      {animalData.sex === "male" ? "Samiec" : "Samica"}
                    </span>
                    <FontAwesomeIcon
                      icon={animalData.sex === "male" ? faMars : faVenus}
                      className={clsx(
                        "text-2xl",
                        animalData.sex === "male"
                          ? "text-blue-500"
                          : "text-pink-500"
                      )}
                    />
                  </h3>
                </li>
                <li className="flex flex-wrap">
                  <h2 className="w-full text-sm font-bold">Wiek</h2>

                  <h3 className="w-full py-2">
                    {createStringAge(animalData?.date_birthday)}
                  </h3>
                </li>
                <li className="flex flex-wrap">
                  <h2 className="w-full text-sm font-bold">
                    Sterylizacja/kastracja
                  </h2>
                  <h3
                    className={clsx(
                      "flex w-full items-center justify-center space-x-2 py-2",
                      animalData.is_sterilized
                        ? "text-green-500"
                        : "text-red-500"
                    )}
                  >
                    <span>{animalData.is_sterilized ? "Tak" : "Nie"}</span>
                    <FontAwesomeIcon
                      icon={animalData.is_sterilized ? faCheck : faTimes}
                      className={clsx(
                        "text-lg",
                        animalData.is_sterilized
                          ? "text-green-500"
                          : "text-red-500"
                      )}
                    />
                  </h3>
                </li>
              </ul>
              {isDifficultToAdopt && (
                <div
                  style={{ background: "#FFF2F2" }}
                  className="my-5 grid min-h-10-screen w-full grid-cols-2 items-center"
                >
                  <FontAwesomeIcon
                    style={{ color: "#FFB085" }}
                    className="mx-auto animate-pulse text-5xl"
                    icon={animalKind === "dog" ? faShieldDog : faShieldCat}
                  />
                  <p
                    style={{ color: "#FFB085" }}
                    className="px-4 text-xl font-semibold"
                  >
                    Zwierzak trudny w adopcji
                  </p>
                </div>
              )}
            </div>
            {/* EMOTION PET DESCRIPTION */}
            <div className="col-span-12 flex h-full flex-wrap md:col-span-6 md:row-span-3 xl:col-span-7 xl:row-span-1 xl:w-full">
              <div className="flex h-full w-full flex-col justify-start space-y-2 md:space-y-8 xl:flex-row xl:justify-around xl:space-x-4 xl:space-y-0">
                {getFeatureNames("character").length > 0 ? (
                  <FeaturesCard
                    theme={gradient}
                    title={"Cechy charakteru"}
                    features={getFeatureNames("character")}
                    featureIcon={faSmileBeam}
                    iconTitle={
                      "Pozytywne emocje - uśmiechnięta buzia. Ikona określająca cechy charakteru zwierzaka"
                    }
                  />
                ) : null}
                {getFeatureNames("need").length > 0 ? (
                  <FeaturesCard
                    theme={gradient}
                    title={"Potrzeby"}
                    features={getFeatureNames("need")}
                    featureIcon={faBone}
                    iconTitle={
                      "Zabawka dla zwierząt - kość. Ikona określająca potrzeby zwierząt"
                    }
                  />
                ) : null}
                {getFeatureNames("others").length > 0 ? (
                  <FeaturesCard
                    theme={gradient}
                    title={"Inne"}
                    features={getFeatureNames("others")}
                    featureIcon={faPaw}
                    iconTitle={
                      "Zwierzęca łapka - odcisk łapy pas lub kota. Ikona określająca inne potrzeby zwierzaka"
                    }
                  />
                ) : null}
              </div>
            </div>
            {/* DESCRIPTION */}
            <div className="order-2 col-span-12 w-full rounded-b-md bg-white md:col-span-6 xl:order-none xl:col-span-5">
              <Headline
                title="Opis"
                level={3}
                className="bg-white text-left font-semibold text-gray-500"
              />
              <div
                className="px-8 py-2 lg:px-16 lg:pb-10 lg:pt-5"
                dangerouslySetInnerHTML={{
                  __html: animalData.description,
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AdoptionDetails;
