import { DateTime } from "luxon";

import { dateDifference } from "./dateDifference";

export const createStringAge = (dateValue: string) => {
  const createdAge = DateTime.now().diff(DateTime.fromISO(dateValue), [
    "years",
    "months",
  ]);

  return dateDifference(
    Math.floor(createdAge.years),
    Math.floor(createdAge.months)
  );
};
