import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

const descriptionItemContainerClassName =
  "flex flex-row xl:flex-col xl:items-center w-full xl:max-w-72 text-center bg-white rounded-b-md shadow-lg transition duration-150 ease-in-out transform xl:hover:scale-105 xl:hover:-translate-y-4";

const FeaturesCard = ({ theme, title, iconTitle, featureIcon, features }) => {
  return (
    <div className={clsx(descriptionItemContainerClassName)}>
      <div
        className={clsx(
          theme.bgStyleLight,
          "flex w-2/6 animate-extend-width flex-col items-center justify-center rounded-b-md py-4 text-white lg:animate-none xl:w-full"
        )}
      >
        <FontAwesomeIcon
          className="animate-set-visible text-5xl"
          aria-label={iconTitle}
          icon={featureIcon}
        />

        <h3 className="my-1 text-sm font-bold">{title}</h3>
      </div>
      <ul className="my-5 ml-auto w-3/6 list-disc xl:ml-0">
        {features.map((feature) => (
          <li
            key={feature}
            className={clsx(theme.textAccentLight, "my-1 text-left")}
          >
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FeaturesCard;

FeaturesCard.propTypes = {
  theme: PropTypes.object,
  title: PropTypes.string,
  iconTitle: PropTypes.string,
  featureIcon: PropTypes.object,
  features: PropTypes.array,
};
