import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { GatsbyImage } from "gatsby-plugin-image";

import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowCircleLeft,
  faArrowCircleRight,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { ThemeContext } from "../context/ThemeContext";

const imageContainerClassName = clsx(
  "flex max-h-50-screen w-full items-center justify-center rounded-md lg:max-h-60-screen"
);
const arrowStyle = clsx("mx-2 cursor-pointer text-2xl");

const PAGINATION_RANGE = 5;

const PhotoPopup = ({
  photo,
  photoAlt = "",
  additionalDescription,
  onClose,
  theme,
  arrowEnabled,
  photos,
  currentPhotoIndex,
  onPhotoChange,
}) => {
  const { isHeightContrastEnable } = useContext(ThemeContext);

  const switchForwardPhoto = () => {
    onPhotoChange(
      currentPhotoIndex === photos.length - 1 ? 0 : currentPhotoIndex + 1
    );
  };
  const switchBackwardPhoto = () => {
    onPhotoChange(
      currentPhotoIndex === 0 ? photos.length - 1 : currentPhotoIndex - 1
    );
  };

  const contrastedButtons = () => {
    if (!isHeightContrastEnable) {
      return theme.textAccent;
    } else {
      return "text-orange-600";
    }
  };

  const handleKeyEvent = (e) => {
    if (e.code === "Escape") {
      onClose();
    } else if (!arrowEnabled) {
      return;
    } else if (e.code === "ArrowLeft") {
      switchBackwardPhoto();
    } else if (e.code === "ArrowRight") {
      switchForwardPhoto();
    }
  };

  const customPagination = (paginationRange) => {
    const arrayOfIndex = photos.map((photo, index) => ({
      id: photo.id,
      index: index,
    }));

    if (
      photos.length >= paginationRange &&
      currentPhotoIndex >= 2 &&
      currentPhotoIndex <= photos.length - 3
    ) {
      return arrayOfIndex.filter(
        (obj) =>
          obj.index >= currentPhotoIndex - 2 &&
          obj.index <= currentPhotoIndex + 1
      );
    }

    if (photos.length >= paginationRange && currentPhotoIndex <= 2) {
      // Length of firsts items in pagination
      return arrayOfIndex.filter((obj) => obj.index <= 3);
    }
    if (
      photos.length >= paginationRange &&
      currentPhotoIndex >= photos.length - 2
    ) {
      // Length of lasts items in pagination
      return arrayOfIndex.filter((obj) => obj.index >= photos.length - 4);
    }

    return arrayOfIndex;
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyEvent);
    return () => document.removeEventListener("keyup", handleKeyEvent);
  });

  return (
    <>
      <div
        id="PopUp"
        // TODO: add X button
        role="presentation"
        onClick={(e) => {
          if (e.target.id !== "img") {
            onClose();
          }
        }}
        className="fixed inset-0 z-50 m-auto flex w-full items-center bg-black/90"
      >
        <button
          className="fixed right-5 top-5 text-2xl"
          onClick={() => onClose()}
        >
          <FontAwesomeIcon
            className={clsx(
              isHeightContrastEnable ? "text-white" : theme.textAccent
            )}
            icon={faTimesCircle}
          />
          <span className="sr-only">Zamknij zdjęcie</span>
        </button>
        <div className="container mx-auto grid grid-cols-1 gap-2  p-2">
          <div>
            {typeof photo === "string" ? (
              <img
                id="img"
                className="mx-auto max-h-90-screen w-auto"
                src={photo}
                alt={photoAlt ?? "Brak informacji"}
              />
            ) : (
              <GatsbyImage
                id="img"
                className={imageContainerClassName}
                image={photo}
                alt={photoAlt ?? "Brak informacji"}
                imgStyle={{ width: "auto", margin: "0 auto" }}
              />
            )}
          </div>
          {additionalDescription && (
            <div className="flex flex-col items-center">
              {photoAlt && photoAlt !== "" ? (
                <h3 className="my-1 min-w-60 rounded-sm bg-white px-5 py-2 text-center text-lg font-semibold">
                  {photoAlt}
                </h3>
              ) : null}
              <p className="my-2 min-w-60 rounded-sm  bg-gray-50 px-5 py-2 text-center text-gray-800">
                {additionalDescription}
              </p>
            </div>
          )}
          {arrowEnabled ? (
            <div
              role="presentation"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className={clsx(
                  "mx-auto my-4 flex animate-fade-in-down items-center justify-between rounded-lg px-2 py-4 duration-200",
                  isHeightContrastEnable ? "bg-white" : "bg-white/20",
                  "hover:bg-white/80"
                )}
              >
                <button onClick={switchBackwardPhoto}>
                  <FontAwesomeIcon
                    id="backwardArrow"
                    icon={faArrowCircleLeft}
                    className={clsx(arrowStyle, theme.textAccent)}
                  />
                  <span className="sr-only">Poprzednie zdjęcie</span>
                </button>
                {photos.length >= PAGINATION_RANGE && (
                  <button
                    className="flex flex-col items-center"
                    onClick={() => onPhotoChange(0)}
                  >
                    <FontAwesomeIcon
                      id="FirstImageArrow"
                      icon={faAngleDoubleLeft}
                      className={clsx(arrowStyle)}
                    />
                    <span className="sr-only">Pierwsze zdjęcie</span>
                  </button>
                )}

                {customPagination(PAGINATION_RANGE).map((photo) => {
                  const isSelected = photo.index === currentPhotoIndex;
                  return (
                    <button
                      disabled={isSelected}
                      onClick={() => onPhotoChange(photo.index)}
                      key={photo.id ?? photo.index}
                    >
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          className={clsx(
                            "mx-2 cursor-pointer text-sm",
                            isSelected ? contrastedButtons() : null
                          )}
                          id={photo.index}
                          icon={faDotCircle}
                        />
                        <span
                          className={clsx(
                            "mt-2",
                            isSelected && [
                              contrastedButtons(),
                              "font-bold underline",
                            ]
                          )}
                        >
                          {photo.index + 1}
                        </span>
                      </div>
                      <span className="sr-only">
                        Wybierz zdjęcie o numerze {photo.index + 1}
                      </span>
                    </button>
                  );
                })}
                {photos.length >= PAGINATION_RANGE && (
                  <button
                    className="flex flex-col items-center"
                    onClick={() => onPhotoChange(photos.length - 1)}
                  >
                    <FontAwesomeIcon
                      id="LastImageArrow"
                      icon={faAngleDoubleRight}
                      className={clsx(arrowStyle)}
                    />
                    <span className="sr-only">Ostatnie zdjęcie</span>
                  </button>
                )}
                <button onClick={switchForwardPhoto}>
                  <FontAwesomeIcon
                    id="forwardArrow"
                    icon={faArrowCircleRight}
                    className={clsx(arrowStyle, theme.textAccent)}
                  />
                  <span className="sr-only">Następne zdjęcie</span>
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PhotoPopup;

PhotoPopup.propTypes = {
  photo: PropTypes.object.isRequired,
  photoAlt: PropTypes.string.isRequired,
  additionalDescription: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  theme: PropTypes.object,
  arrowEnabled: PropTypes.bool.isRequired,
  photos: PropTypes.array,
  currentPhotoIndex: PropTypes.number,
  onPhotoChange: PropTypes.func,
};
