export const dateDifference = (years: number, months: number) => {
  const dateDifferenceDisplay = {
    lessThanMonth: "Poniżej miesiąca",
    year: "Rok",
    month: "Miesiąc",
    monthsAge: "miesiące(y)",
    yearsAge: "lat(a)",
  };

  // Less than month
  if (months < 1 && years < 1) {
    return dateDifferenceDisplay.lessThanMonth;
  }

  // Less than year equal month
  if (years < 1 && months === 1) {
    return dateDifferenceDisplay.month;
  }
  // Less than year more than month
  if (years < 1 && months > 1) {
    return `${months} ${dateDifferenceDisplay.monthsAge}`;
  }
  // Only year
  if (years === 1 && months < 1) {
    return dateDifferenceDisplay.year;
  }
  // Year and month
  if (years === 1 && months === 1) {
    return `${
      dateDifferenceDisplay.year
    } i ${months} ${dateDifferenceDisplay.month.toLowerCase()}`;
  }
  // Year and more than month
  if (years === 1 && months > 1) {
    return `${dateDifferenceDisplay.year} i ${months} ${dateDifferenceDisplay.monthsAge}`;
  }
  // More than Year less than month
  if (years > 1 && months < 1) {
    return `${years} ${dateDifferenceDisplay.yearsAge}`;
  }
  // More than Year eqyal month
  if (years > 1 && months === 1) {
    return `${years} ${
      dateDifferenceDisplay.yearsAge
    } ${months} ${dateDifferenceDisplay.month.toLowerCase()}`;
  }
  // More than Year and more than month
  if (years > 1 && months > 1) {
    return `${years} ${dateDifferenceDisplay.yearsAge} ${months} ${dateDifferenceDisplay.monthsAge}`;
  }
};
